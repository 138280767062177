@import "Header.css";
@import "Footer.css";
@import "Home.css";
@import "Navbar.css";
@import "About.css";
@import "Categories.css";

.App{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}



