header {
    width: 100vw;
    display: flex;
    position: fixed;
    padding: 0px;
    overflow: hidden;
    background-size: cover;
}

header img {
    width: 100px;
    padding-left: 10px;
    padding-right:  50px;
    padding-top:  10px;
    padding-bottom:  10px;
}
