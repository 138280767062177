nav {
    display: flex;
    flex-direction: row;
}

.nav-div {
    display: flex;
    align-items: center;
    
}

.nav-link{
    color: rgba(255, 255, 255, 0.708);
    font-size: 30px;
    margin-right: 20px;
    text-decoration: none;
}

.nav-link:hover{
    color: white;
}

.nav-link.active{
    color: white;
    text-decoration: underline;
}

.nav-desktop{
    padding-right: 200px;
    
}

.nav-mobile{
    display: none;
    font-size: 60px;
    color: rgba(255, 255, 255, 0.807);
    padding-left: 100px;
}

.mobile-menu {
    display: none;
    
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .nav-desktop{
        display: none;
    }
    .nav-mobile{
        display: block;        
    }
    .mobile-menu {
        padding:10px 5px;
        width: 100vw;
        margin: 0;
        display: flex;
        align-items: center;
        position: fixed;
        top: 113px;
        flex-direction: column;
        background-color: black;
           
    }
    .mobile-menu .nav-link {
        margin: 0px;
    }
    nav {
        flex-direction: column;
    }
    .close-menu-container{
        width: 100%;
        height: 100vh;
        position: absolute;
    }
}