.category-card{
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    border: solid 1px rgba(0, 0, 0, 0.123);
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.category-card img{
    width: 120px;
    height: 120px;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
}

.card-content{
    width: 500px;
    /* border: solid 1px red; */
}

.card-numbers{
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
}