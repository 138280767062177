footer {
    background-color: rgb(19, 94, 97);
    padding: 10px;
    display: flex;
    
    justify-content: space-between;
    
}

footer section {
    width: 300px;
    color: rgba(255, 255, 255, 0.562); 
    padding-left: 50px;
    padding-right: 50px;
}